import { Flex } from "components/flex" 
import styles from "./Subscription.module.css";
import { Tab } from "components/tab";
import { Divider, Modal, Table } from "antd";
import ColumnGroup from "antd/es/table/ColumnGroup";
import Column from "antd/es/table/Column";
import { Button } from "components/button";
import { useState,useEffect } from "react"; 
import { addCommas, callApi } from 'utils';

const historyColumn = [
  {
    title: '이용 기간',
    dataIndex: 'history1',
    key: 'history1',
  },
  {
    title: '생성일',
    dataIndex: 'history2',
    key: 'history2',
  },
  {
    title: '수정일',
    dataIndex: 'history3',
    key: 'history3',
  },
]

export const SubscriptionManage = () => {
  const [data, setData] = useState([]);
  const [totalEmpCnt, setTotalEmpCnt] = useState(0);
  const [empOriCnt, setEmpOriCnt] = useState(0);
  const [salaryOriCnt, setSalaryOriCnt] = useState(0);
  const [employeeCountModal, setEmployeeCountModal] = useState(false);
  const [amountByAgencyModal, setAmountByAgencyModal] = useState(false);

  useEffect(() => {

    const companyFeeCount = async () => {
      let sessionStorage = window.sessionStorage;
      let opId = sessionStorage.getItem("userId");

      const postData = {
        opId: opId
      };

      const response = await callApi('api/platform/companyFeeCount', postData);
      if (response&&response.result) {
        setData(response.data); 
        setSalaryOriCnt(response.salaryOriCnt);
        setEmpOriCnt(response.empOriCnt);
        setTotalEmpCnt(response.totalEmpCnt);
      }

    }
    companyFeeCount(); 
  }, []);


  const columns = [
    {
      title: '수임업체명',
      dataIndex: 'mbName',
      key: 'mbName',
    },
    {
      title: '23년 11월 귀속연월 급여대장 사원수',
      dataIndex: 'salaryCnt',
      key: 'salaryCnt',
    },
    {
      title: '재직중 사원수 (근로계약서 확정)',
      dataIndex: 'empCnt',
      key: 'empCnt',
    },
    {
      title: '총합',
      dataIndex: 'totalEmpCnt',
      key: 'totalEmpCnt',
      width:"70px"
    },
    {
      title: '인당 비용',
      dataIndex: 'key4',
      key: 'key4', 
      render: (text, record) => {
        return 100 + "원";
      },
      width:"100px"
    },
    {
      title: '금액',
      dataIndex: 'totalCnt',
      key: 'totalCnt',
      render: (text, record) => {
        return addCommas(record.totalCnt*100) + "원";
      },
      width:"100px"
    },
  ];


  const tabItems = () => {
    return [
      {
        key: '1',
        label: '결제 내역',
        children: <Flex direction="column" >
          <Flex direction="column" alignItems="flex-start" style={{ marginTop: 40, width: "100%" }}>
            <h3>결제 금액</h3>
            <div className={styles.wage_box}>
              <Flex justifyContent="space-between" className={styles.wage_box_item}>
                <Flex direction="column" rowGap={15} alignItems="flex-start">
                  <span className={styles.box_title}>대상 사원수</span>
                  <span className={styles.box_content}>{addCommas(totalEmpCnt)}명</span>
                </Flex>
                <Button
                  type="text"
                  size="sm"
                  onClick={() => setEmployeeCountModal(true)}
                  style={{
                    padding: 0,
                    color: 'var(--expert-primary-purple-500, #866DEC)',
                    fontSize: "14px",
                  }}
                >
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39763 14.6025 1.66667 10.0001 1.66667C5.39771 1.66667 1.66675 5.39763 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z" fill="#E2E5EA" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.0001 5.97223C10.3836 5.97223 10.6945 6.28314 10.6945 6.66667V10C10.6945 10.3835 10.3836 10.6945 10.0001 10.6945C9.61655 10.6945 9.30564 10.3835 9.30564 10V6.66667C9.30564 6.28314 9.61655 5.97223 10.0001 5.97223ZM9.30564 13.3333C9.30564 12.9498 9.61655 12.6389 10.0001 12.6389H10.0084C10.3919 12.6389 10.7029 12.9498 10.7029 13.3333C10.7029 13.7169 10.3919 14.0278 10.0084 14.0278H10.0001C9.61655 14.0278 9.30564 13.7169 9.30564 13.3333Z" fill="#52545C" />
                  </svg>
                  대상 사원수란?
                </Button>
              </Flex>
              <Divider type="vertical" style={{ height: 45 }} />
              <Flex direction="column" rowGap={15} alignItems="flex-start" className={styles.wage_box_item}>
                <span className={styles.box_title}>금액</span>
                <span className={styles.box_content}>{addCommas(totalEmpCnt* 100)}원</span>
              </Flex>
              <Divider type="vertical" style={{ height: 45 }} />
              <Flex direction="column" rowGap={15} alignItems="flex-start" className={styles.wage_box_item}>
                <span className={styles.box_title}>결제일</span>
                <span className={styles.box_content}>2024.02.20</span>
              </Flex>
            </div>
            <h3>결제 내역</h3>
            <Table style={{ width: '100%', marginTop: 40 }}>
              <Column title="결제 일시" dataIndex="date" key="date" />
              <Column title="결제 금액" dataIndex="price" key="price" />
              <Column title="결제 수단" dataIndex="means" key="means" />
              <ColumnGroup title="이용권 정보">
                <Column title="이용권 기간" dataIndex="period" key="period" />
                <Column title="직원수" dataIndex="employee" key="employee" />
              </ColumnGroup>
              <Column title="결제 번호" dataIndex="number" key="number" />
            </Table>
          </Flex>
        </Flex>,
      },
      {
        key: '2',
        label: '이용권 변경 내역',
        children: <div style={{ marginTop: 40, width: "100%" }}>
          <Table columns={historyColumn} />
        </div>
      }
    ]
  }
  return (
    <>
      <Flex direction="column" alignItems="flex-start" rowGap={40} style={{ marginBottom: 120, width: "100%" }} className={styles.manger_container}>
        <h2 style={{ fontSize: 24, fontWeight: "bold" }}>이용권 관리</h2>
        <Tab items={tabItems()} style={{ width: "100%" }} />
      </Flex>
      {
        employeeCountModal &&
        <Modal
          title={"대상 사원수란?"}
          open={employeeCountModal}
          closeIcon={false}
          width={753}
          centered={true}
          footer={
            <Button type="secondary" size="sm" onClick={() => setEmployeeCountModal(false)}>
              닫기
            </Button>
          }
          styles={{
            body: {
              padding: "40px 20px",
            }
          }}
        >
          <Flex direction="column" rowGap={24}>
            <Flex columnGap={24}>
              <Flex direction="column" rowGap={16} style={{ width: 156 }}>
                <span className={styles.employee_count_title}>23년 11월 귀속연월<br />급여대장 사원수</span>
                <div className={styles.count_box}>{addCommas(salaryOriCnt)}명</div>
                <span className={styles.employee_count_content}>결제일의 전월 귀속연월의 급여대장에 속한 사원수에요.</span>
              </Flex>
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.875 12.0625V7.67969H0.492188V4.75H4.875V0.367188H7.80469V4.75H12.1875V7.67969H7.80469V12.0625H4.875Z" fill="#ACB1BA" />
              </svg>
              <Flex direction="column" rowGap={16} style={{ width: 156 }}>
                <span className={styles.employee_count_title}>현재까지 재직중 사원수<br/>(근로계약서 확정 상태)</span>
                <div className={styles.count_box}>{addCommas(empOriCnt)}명</div>
                <span className={styles.employee_count_content}>퇴직자, 휴직자, 입사 예정자는 제외된 사원수에요</span>
              </Flex>
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.65625 2.91406V0.03125H12.0469V2.91406H0.65625ZM0.65625 8.39844V5.51562H12.0469V8.39844H0.65625Z" fill="#ACB1BA" />
              </svg>
              <Flex direction="column" rowGap={16} style={{ width: 156, alignSelf: "flex-end" }}>
                <div className={styles.count_box}>{addCommas(totalEmpCnt)}명</div>
                <span className={styles.employee_count_content}>사번 중복 제거된 전체 사원수에요.</span>
              </Flex>
            </Flex>
            <Button type="line-purple" size="sm" style={{ alignSelf: 'flex-end', marginRight: 50 }} onClick={() => setAmountByAgencyModal(true)}>수임업체별 보기</Button>
          </Flex>
        </Modal>
      }
      {
        amountByAgencyModal &&
        <Modal
          title={"수임업체별 금액"}
          open={amountByAgencyModal}
          closeIcon={false}
          width={753}
          centered={true}
          footer={
            <Button type="secondary" size="sm" onClick={() => setAmountByAgencyModal(false)}>
              닫기
            </Button>
          }
          styles={{
            body: {
              padding: "40px 20px",
            }
          }}
        >
          <Table columns={columns} dataSource={data}
           pagination={{
            pageSize: 5
          }}
          />
        </Modal>
      }
    </>
  )
}