import { useState, useEffect } from "react";
import { useQuery } from "react-query";

import { ConfigProvider, Popover, Table, Tag, message } from "antd"

import { Flex, SelectBox, SearchBox, NotificationCard, MultiButton, Button, Tooltip, Toggle } from "components/hulam_platform"
import { CheckReturnOffIcon, DotsHorizontalIcon, InfoRedIcon, UserIcon, CheckCircleOFFIcon, CheckWaitOffIcon, HelpCircleGrayIcon } from "components/icon"
import { alert, callApi, handlerEventLog, snackBar } from 'utils';

import AccountSharingModal from "./modal/AccountSharingModal";
import AccountConnectModal from "./modal/AccountConnectModal";
import EmployeePicker from "./modal/EmployeePicker";
import InsuranceLinkModal from "./modal/InsuranceLinkModal";
import CompanyAddModal from "./modal/CompanyAddModal";
import CompanyModifyModal from "./modal/CompanyModifyModal";
import ModifyLinkedAccountModal from "./modal/ModifyLinkedAccountModal";
import PublicCertImportModal from "../Config/modal/PublicCertImportModal";




import { useAuth } from "AuthProvider";
import tooltipImg from "assets/images/content_1.jpg";
import down from "assets/images/down.png";

export const HulamCompanyList = () => {

  const { author, companyName: opCompanyName } = useAuth();
  const [accountSharingModalOpen, setAccountSharingModalOpen] = useState(false);
  const [accountConnectModalOpen, setAccountConnectModalOpen] = useState(false);
  const [insuranceLinkModalOpen, setInsuranceLinkModalOpen] = useState(false);
  const [modifyLinkedAccountModal, setModifyLinkedAccountModal] = useState(false);
  const [popup, setPopup] = useState(false);
  const [certImportModal, setCertImportModal] = useState(false);
  const [isAddCompany, setIsAddCompany] = useState(false);
  const [isModifyCompany, setIsModifyCompany] = useState(false);
  const [mbId, setMbId] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [isReloading, setIsReloading] = useState(false);
  const [isDupl, setIsDupl] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);

  const [searchData, setSearchData] = useState({
    type: '',
    text: ''
  });
  const [workObj, setWorkObj] = useState(null);
  const [empPicker, setEmpPicker] = useState(false);
  const [certYn, setCertYn] = useState("N");
  const [rows, setRows] = useState(20);


  const companyList = async () => {
    let sessionStorage = window.sessionStorage;
    let opId = sessionStorage.getItem("userId");
    let opeNo = sessionStorage.getItem("opeNo");
    let email = sessionStorage.getItem("email");
    if (author === "manager") {
      const postData = {
        opId: opId,
        searchText: searchData.text,
        searchType: searchData.type,
        isDupl: isDupl ? "Y" : "N",
        isHide: isHide ? "Y" : "N",
      };

      const response = await callApi('api/platform/companyList', postData);

      if (response && response.data) {
        return response.data.map((item, index) => ({ ...item, key: `${item.name}_${index}` }));
      } else {
        return [];
      }

    } else {
      const postData = {
        opId: opId,
        searchText: searchData.text,
        searchType: searchData.type,
        opeNo: opeNo,
        email: email,
        isDupl: isDupl ? "Y" : "N",
        isHide: "N"
      };

      const response = await callApi('api/platform/companyList', postData);

      if (response && response.data) {
        return response.data.map((item, index) => ({ ...item, key: `${item.name}_${index}` }));
      } else {
        return [];
      }
    }

  }

  const { isLoading, isError, data, error, refetch } = useQuery(`hulamCompanyList/${isDupl}/${isHide}`, companyList)


  useEffect(() => {
    certStatus();
  }, [isDupl, isHide, searchData, author])


  const pollingCheckInsurance = async () => {
    const firstCheck = await companyStatus();

    if (~~firstCheck > 0) {

      snackBar(<>국민연금 EDI에서 수임업체를 불러오는 중이에요. (1/3)<br />최대 30초 소요될 수 있어요.</>, { type: "loading", duration: 30 })

      let step = 1;
      const stepTimer = setInterval(() => {
        step++;
        if (step === 2) {
          message.destroy();
          snackBar(<>건강보험 EDI에서 수임업체를 불러오는 중이에요. (2/3)<br />최대 30초 소요될 수 있어요.</>, { type: "loading", duration: 30 })
        }
        if (step === 3) {
          message.destroy();
          snackBar(<>고용산재 토탈서비스에서 수임업체를 불러오는 중이에요. (3/3)<br />최대 1분 소요될 수 있어요.</>, { type: "loading", duration: 300 })
        }
      }, 30000);

      const pollTimer = setInterval(() => {
        companyStatus().then((result) => {

          if (~~result > 0) {
          } else {
            message.destroy();

            if (data.length < 1) {
              const eventLog = {
                href: window.location.href,
                event_type: 'initial_fetch_firms_result',
                userId: opId,
                category: 'onboard',
                message: "success",
                source: "",
                label1: "",
                label2: "",
                slack_message: `[수임업체 최초 등록 시도 결과]\n업무 대행사:${opCompanyName} (${opId})\n결과:성공`,
                slack_type : 'onborading'
              }

              handlerEventLog(eventLog)
            }


            snackBar(<>연동이 완료되었습니다.</>);
            clearInterval(pollTimer); // 원하는 조건 충족 시 폴링 중지
            clearInterval(stepTimer);
            setIsReloading(false);
          }
        }).catch((error) => {

          if (data.length < 1) {
            const eventLog = {
              href: window.location.href,
              event_type: 'initial_fetch_firms_result',
              userId: opId,
              category: 'onboard',
              message: "fail",
              source: "",
              label1: "",
              label2: "",
              slack_message: `[수임업체 최초 등록 시도 결과]\n업무 대행사:${opCompanyName} (${opId})\n결과:실패`,
              slack_type : 'onborading'
            }

            handlerEventLog(eventLog)
          }
          // 에러 처리
          snackBar(<>연동에 실패했어요.</>);

          clearInterval(pollTimer); // 에러 발생 시 폴링 중지
          clearInterval(stepTimer);
          setIsReloading(false);
        });

      }, 5000)
    }
  }

  const reload = async () => {
    setIsReloading(true);
    const postData = {
      opId: opId

    };

    await callApi('api/platform/companyReloadAll', postData);

    if (data.length < 1) {
      const eventLog = {
        href: window.location.href,
        event_type: 'initial_fetch_firms',
        userId: opId,
        category: 'onboard',
        message: "",
        source: "",
        label1: "",
        label2: "",
        slack_message: `[수임업체 최초 등록 시도]\n업무 대행사:${opCompanyName} (${opId})`,
        slack_type : 'onborading'
      }
      await handlerEventLog(eventLog)
    }

    pollingCheckInsurance();
  }


  const certStatus = async () => {
    let sessionStorage = window.sessionStorage;
    let opId = sessionStorage.getItem("userId");

    const postData = {
      opId: opId
    };

    const response = await callApi('api/platform/certStatus', postData);

    if (response && response.result) {
      setCertYn(response.certYn);
    }
  }

  const companyReload = () => {
    //cert 등록 여부 
    reload();

    //setCertImportModal(true)
  }


  const companyStatus = async () => {
    //cert 등록 여부 
    let sessionStorage = window.sessionStorage;
    let opId = sessionStorage.getItem("userId");

    const postData = {
      opId: opId
    };
    const response = await callApi('api/platform/loadStatus', postData);

    if (response && response.result) {
      return response.batchCnt;
    }
  }

  const selectCompany = async (record) => {
    setSelectedData(record);
    setAccountConnectModalOpen(true);
  }

  const supportHulam = async (record) => {
    setSelectedData(record);
    setAccountSharingModalOpen(true)
  }


  const openWindow = (mbId) => {
    // 새 창 열기 

    let targetWindow = window.open('', 'hulampro');

    if (!targetWindow || targetWindow.closed) {
      // 창이 없으면 열기
      targetWindow = window.open('', 'hulampro');
    }

    // 열린 창에 포커스 맞추기
    if (targetWindow) {
      targetWindow.focus();
    }



    let ROOT_URL = "https://dev2.hulam.co.kr";
    if (window.location.hostname.includes('dev.hulampro.co.kr')) {
      ROOT_URL = "https://dev2.hulam.co.kr";
    } else if (window.location.hostname.includes('hulampro.co.kr')) {
      ROOT_URL = "https://hulam.co.kr";
    } else {
      ROOT_URL = "http://localhost:8000";
    }

    // 창이 없으면 열기
    let form = document.createElement('form');
    form.method = 'post';
    form.action = `${ROOT_URL}/`;
    form.target = 'hulampro';
    document.body.appendChild(form);
    form.submit();

    form = document.createElement('form');
    form.method = 'post';
    form.action = `${ROOT_URL}/hpro/_adm_login_check_from_platform.php`;
    form.target = 'hulampro';


    // 폼 데이터 추가
    const op_id = document.createElement('input');
    op_id.type = 'hidden';
    op_id.name = 'op_id';
    op_id.value = opId;
    form.appendChild(op_id);

    const manager = document.createElement('input');
    manager.type = 'hidden';
    manager.name = 'manager';
    manager.value = (author === "manager" ? "Y" : "N");
    form.appendChild(manager);



    if (author === "employee") {
      const ope_no = document.createElement('input');
      ope_no.type = 'hidden';
      ope_no.name = 'opeNo';
      ope_no.value = opeNo;
      form.appendChild(ope_no);

    }

    const authkey = document.createElement('input');
    authkey.type = 'hidden';
    authkey.name = 'authkey';
    authkey.value = accessToken;
    form.appendChild(authkey);


    const mb_id = document.createElement('input');
    mb_id.type = 'hidden';
    mb_id.name = 'mb_id';
    mb_id.value = mbId;
    form.appendChild(mb_id);




    // 나머지 폼 데이터 추가

    // 폼을 body에 추가하고 submit
    document.body.appendChild(form);
    form.submit();

  };
 
  const deleteCompany = async (record) =>{
    alert({
      type: "confirm",
      icon: true,
      content: "해당 수임업체의 정보가 삭제 됩니다. ",
      onOk: async () => {
        let sessionStorage = window.sessionStorage;
        let opId = sessionStorage.getItem("userId");

        const postData = {
          opId: opId,
          businessNum: record.businessNum
        };
        const response = await callApi('api/platform/deleteCompany', postData);

        if (response&&response.result) {
          snackBar(<>사업장이 삭제 되었어요.</>);
          refetch();
        }
      }
    })
    
  }
  const hideCompany = async (record) => {
    //cert 등록 여부 
    let sessionStorage = window.sessionStorage;
    let opId = sessionStorage.getItem("userId");

    const postData = {
      opId: opId,
      businessNum: record.businessNum
    };
    const response = await callApi('api/platform/hideCompany', postData);

    if (response && response.result) {
      snackBar(<>사업장이 비노출 되었어요.</>);
      refetch();
    }
  }

  const showCompany = async (record) => {
    //cert 등록 여부 
    let sessionStorage = window.sessionStorage;
    let opId = sessionStorage.getItem("userId");

    const postData = {
      opId: opId,
      businessNum: record.businessNum
    };
    const response = await callApi('api/platform/showCompany', postData);

    if (response && response.result) {
      snackBar(<>사업장이 보이기 되었어요.</>);
      refetch();
    }
  }

  const columns = [
    {
      title: '사업장명',
      fixed: true,
      dataIndex: 'companyName',
      key: 'companyName',
      zIndex: 1200,
      width: 210,
      sorter: (a, b) => a.companyName.localeCompare(b.companyName, 'ko'),
    },
    {
      title: '사업장 관리번호 - 수임종류',
      dataIndex: 'businessNum',
      key: 'businessNum',
      width: 254,
      render: (text, record) => {
        return <div>{record.insuranceType} {record.businessNum}</div>
      }

    },
    {
      title: '담당 직원',
      dataIndex: 'worker',
      key: 'worker',
      width: 248,
      sorter: (a, b) => {
        const data1 = a.name || "";
        const data2 = b.name || "";

        return data1.localeCompare(data2, 'ko')
      },
      render: (text, record) => {
        if (record.opeCount === '1') {
          return <div>{record.name}</div>
        } else if (record.opeCount > 1) {
          return <div>{record.name} 외 {record.opeCount - 1}명</div>
        } else {
          return <div> </div>
        }
      }
    },
    {
      title: '휴램 계정 연결 상태',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      sorter: (a, b) => {
        const data1 = a.mbId ? "complete" : 'failed';
        const data2 = b.mbId ? "complete" : 'failed';

        return data1.localeCompare(data2, 'ko')
      },
      render: (text, record) => {
        if (record && record.mbId && (record.isHulamproAgree === "Y")) {
          return <Flex><CheckCircleOFFIcon /> 연결 완료</Flex>
        } else if (record && record.mbId && record.isHulamproAgree === "N") {
          return <Flex><CheckWaitOffIcon /> 연결 동의 대기중</Flex>
        } else {

          return <Flex>
            <CheckReturnOffIcon /> 연결 실패
            <Tooltip title={<>사업자 등록번호가 아니거나 이미 동일한 사업자 등록 번호를 사용하는 휴램 계정이 있어요. 사업자 계정 선택하기를 통해 이 수임 업체 관리에 사용될 휴램 계정 하나를 선택해 주세요.</>} overlayInnerStyle={{ width: 250 }}>
              <InfoRedIcon size={20} />
            </Tooltip>
          </Flex>
        }
      },
    },
    {
      title: '작업',
      dataIndex: 'work',
      key: 'work',
      render: (text, record) => {
        return (
          <MultiButton>
            {author === "manager" ? <Button type="line-gray" size="sm" onClick={() => {
              console.log(record);
              if (record.mbId && record.isHulamproAgree !== "N") {
                settingEmployee(record);
              } else {
                snackBar("휴램 계정이 연결된 경우에 담당 직원을 설정할 수 있어요");
              }
            }}><UserIcon size={20} />담당 직원 설정</Button> : null}
            {record.mbId && record.isHulamproAgree === "Y" ?
              <Tooltip title={<>휴램으로 로그인 시 휴램 홈페이지에서<br /> 급여 관리 메뉴를 사용할 수 있어요.</>}>
                <Button type="line-purple" size="sm"
                  onClick={() => {
                    if (record.mbId && record.isHulamproAgree === "Y") {
                      onSubmit(record.mbId);
                      openWindow(record.mbId);
                    } else {
                      snackBar("휴램 계정이 연결된 경우에 사업장으로 로그인할 수 있어요");
                    }
                  }}
                > 사업장으로 로그인하기</Button>
              </Tooltip>
              : null}
            {record.mbId && record.isHulamproAgree !== "N" && record.isTmpPassword === "Y" ?
              <Tooltip title={<>해당 회사의 담당자에게 휴램에 접속할<br /> 수 있도록 정보를 전달 할 수 있어요.</>}>
                <Button type="line-purple" size="sm" onClick={() => { supportHulam(record); }}>휴램 접속 계정 전달하기</Button>
              </Tooltip> : null}
            {!record.mbId ?
              <Tooltip title={<>이 수임 업체의 사업자 등록번호와 동일한<br /> 휴램 계정이 있어요.<br />이 수임 업체 관리에 사용될 휴램 계정을<br /> 하나만 선택해 주세요.</>}>
                <Button type="line-purple" size="sm" onClick={() => { selectCompany(record); }}>사업장 계정 선택하기</Button>
              </Tooltip> : null}
            {/* 연결 요청 중일때만 */}
            {record.mbId && record.isHulamproAgree === "N" ?
              <Button type="line-purple" size="sm" onClick={() => handleConnectCancel(record.mbId, record.businessNum)}>연결 요청 취소하기</Button> : null
            }
            {author === "manager" ?
              <div>

            {record.mbId && (record.isHulamproAgree === "Y" ||record.isHulamproAgree === "") ?
              (record.isHide ==="N"?<SelectBox
              value={<DotsHorizontalIcon size={20} />}
              placeholder={<DotsHorizontalIcon size={20} />}
              placement={"bottomRight"}
              style={{ width: 70 }}
              options={
                record.isInsuranceCompany==="Y"?
                [{ value: "1", label: '연결된 휴램 계정 변경하기' }, { value: "2", label: '사업장 숨기기' }, { value: "4", label: '사업장 수정' }]
              :[{ value: "1", label: '연결된 휴램 계정 변경하기' }, { value: "2", label: '사업장 숨기기' }, { value: "4", label: '사업장 수정' }, { value: "5", label: '사업장 삭제' }]}
              dropdownStyle={{
                width: 182
              }}
              onSelect={(e) => { 
                if(e==="1"){
                  setSelectedData(record);
                  setModifyLinkedAccountModal(true)
                }else if(e==="2"){  
                  hideCompany(record);
                }else if(e==="4"){
                  //사업장 수정
                  setSelectedData(record);
                  setIsModifyCompany(true);
                }else if(e==="5"){
                  //사업장 삭제하기
                  deleteCompany(record);
                }
              
                
              }}
                />
                :<SelectBox
                value={<DotsHorizontalIcon size={20} />}
                placeholder={<DotsHorizontalIcon size={20} />}
                placement={"bottomRight"}
                style={{ width: 70 }}
                options={
                  record.isInsuranceCompany==="Y"?
                  [{ value: "1", label: '연결된 휴램 계정 변경하기' }, { value: "3", label: '숨긴 사업장 보이기' }, { value: "4", label: '사업장 수정' }]:
                  [{ value: "1", label: '연결된 휴램 계정 변경하기' }, { value: "3", label: '숨긴 사업장 보이기' }, { value: "4", label: '사업장 수정' }, { value: "5", label: '사업장 삭제' }]}
                dropdownStyle={{
                  width: 182
                }}
                onSelect={(e) => { 
                  if(e==="1"){
                    setSelectedData(record);
                    setModifyLinkedAccountModal(true)
                  }else if(e==="2"){  
                    hideCompany(record);
                  }else if(e==="3"){  
                    showCompany(record);
                  }else if(e==="4"){
                    //사업장 수정
                    setSelectedData(record);
                    setIsModifyCompany(true);
                  }else if(e==="5"){
                    //사업장 삭제하기
                    deleteCompany(record);
                  }
                  
                }}
              />): 
              
              (record.isHide ==="N"?<SelectBox
                value={<DotsHorizontalIcon size={20} />}
                placeholder={<DotsHorizontalIcon size={20} />}
                placement={"bottomRight"}
                style={{ width: 70 }}
                options={
                  record.isInsuranceCompany==="Y"?
                  [  { value: "2", label: '사업장 숨기기' }]:
                  [  { value: "2", label: '사업장 숨기기' }, { value: "4", label: '사업장 수정' }, { value: "5", label: '사업장 삭제' }]
                }
                dropdownStyle={{
                  width: 182
                }}
                onSelect={(e) => { 
                    if(e==="2"){ 
                      hideCompany(record);
                    
                    }else if(e==="4"){
                      //사업장 수정
                      setSelectedData(record);
                      setIsModifyCompany(true);
                    }else if(e==="5"){
                      //사업장 삭제하기
                      deleteCompany(record);
                    }
                  
                }}
              />:
              <SelectBox
                value={<DotsHorizontalIcon size={20} />}
                placeholder={<DotsHorizontalIcon size={20} />}
                placement={"bottomRight"}
                style={{ width: 70 }}
                options={
                  record.isInsuranceCompany==="Y"?
                  [  { value: "3", label: '숨긴 사업장 보이기' }]:
                  [  { value: "3", label: '숨긴 사업장 보이기' }, { value: "4", label: '사업장 수정' }, { value: "5", label: '사업장 삭제' }]}
                dropdownStyle={{
                  width: 182
                }}
                onSelect={(e) => { 
                    if(e==="2"){ 
                      hideCompany(record);
                    
                    }else if(e==="3"){  
                      showCompany(record);
                    }else if(e==="4"){
                      //사업장 수정
                      setSelectedData(record);
                      setIsModifyCompany(true);
                    }else if(e==="5"){
                      //사업장 삭제하기
                      deleteCompany(record);
                    }
                  
                }}
              />)
            }
            </div>:null}
            
            

          </MultiButton>
        )
      }
    },
  ];

  useEffect(() => {
    if (mbId) {
      /*
      if (formRef.current) {
        
        //formRef.current.submit();
       // openWindow();
      
      }
      */
      //closeAndOpenWindow(mbId);
      //openWindow();
    }

  }, [mbId])
  const settingEmployee = async (record) => {
    setEmpPicker(true);
    setWorkObj(record);
  }

  const handleConnectCancel = async (mbId, businessNum) => {
    alert({
      type: "confirm",
      icon: true,
      content: "연결 요청을 취소하시겠습니까?",
      onOk: async (e) => {
        const postData = {
          opId: opId,
          mbId: mbId,
          businessNum: businessNum
        };
        const response = await callApi('api/platform/cancelRequestConnect', postData);

        console.log(response);
        snackBar('연결 요청이 취소 되었습니다.');
        refetch();
      }
    })
  }

  const sharingOk = () => {
    snackBar('휴램 접속 계정이 전달되었습니다.');
    setAccountSharingModalOpen(false);
  }

  const ConnectOk = () => {
    snackBar('사업장 계정이 선택되었습니다.');
    refetch();
    companyList();
    setAccountConnectModalOpen(false);
  }

  const linkedOk = () => {
    setCertImportModal(true);
    setInsuranceLinkModalOpen(false);
  }

  const modifyOk = () => {
    snackBar('휴램 계정이 변경되었습니다.');
    refetch();
    setModifyLinkedAccountModal(false);
  }

  const importPublicCert = async (formData) => {
    setCertImportModal(false);
  }

  const customizeRenderEmpty = () => (
    <Flex
      justifyContent="center"
      style={{
        height: 178,
        background: "var(--gray-50-background)"
      }}
    >
      <Flex direction="column" rowGap={24}>
        내역이 없어요.
        {certYn === "N" && author === "manager" ? <Button size="sm" width={230} onClick={() => setInsuranceLinkModalOpen(true)}>4대보험 연동으로 수임 업체 가져오기</Button> : null}

      </Flex>
    </Flex>
  );
  const onSubmit = (mbId) => {

    setMbId(mbId);


  }
  /*
  const handleSubmit = (event) => {
    event.preventDefault();

  };
  */


  const addCompany = (status) => {
    if (status === "success") {
      refetch();
      snackBar("수임업체 등록이 완료되었습니다.");
    }

    setIsAddCompany(false);
  }

  const handleCancel = () => {
    setIsAddCompany(false);

  }

  const modifyCompany = (status) => {
    console.log(status);
    if (status === "success") {
      refetch();
      snackBar("수임업체 수정이 완료되었습니다.");
    }

    setIsModifyCompany(false);
  }

  const handleModifyCancel = () => {
    setIsModifyCompany(false);
    
  }
  
  const handleMouseEnter = (event) => {

    event.target.style.background = '#E2E5EA';

  };

  const handleMouseLeave = (event) => {
    event.target.style.background = 'white';
  };



  if (isError) {
    return <span>Error: {error.message}</span>
  }

  let opId = sessionStorage.getItem("userId");

  let opeNo = sessionStorage.getItem("opeNo");
  let accessToken = sessionStorage.getItem("accessToken");


  return (
    <div>
      <Flex justifyContent="space-between">
        <h2 style={{ fontSize: 24, fontWeight: "bold" }}>수임 업체</h2>
        {
          certYn === "Y" && 
          <Tag
            icon={<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="4" cy="4" r="4" fill="#03CF5D" />
            </svg>
            }
            color="success"
          >
            4대 보험 연동중
            {
              author === "manager" ? (
                isReloading ? <span style={{marginLeft: 8, color: "var(--primary-green-600, #10A54C)", textDecorationLine: "underline"}}>가져오는 중</span> :
                  <Button
                    type="text"
                    style={{
                      display: "inline-block",
                      padding: 0,
                      marginLeft: 8,
                      height: "auto",
                      color: "var(--primary-green-600, #10A54C)",
                      fontSize: 12,
                      fontWeight: 500,
                      lineHeight: "12px",
                      textDecorationLine: "underline"
                    }}
                    onClick={() => { companyReload() }}
                  >
                    수임 업체 다시 가져오기
                  </Button>
              ) : null
            }
          </Tag>
        } 
      </Flex>
      {
        data?.length >= 0 ? (
          <>
            <Flex style={{ marginTop: 30, marginBottom: 24 }} justifyContent="space-between">
              <Flex >
                <SelectBox
                  defaultValue={searchData.type}
                  popupMatchSelectWidth={false}
                  value={searchData.type}
                  style={{ width: '118px' }}
                  onChange={(e) => setSearchData({ ...searchData, type: e })}
                  options={[
                    {
                      value: "", label: "선택"
                    },
                    {
                      value: "companyName", label: "수임 업체명"
                    },
                    {
                      value: "employeeName", label: "담당 직원명"
                    },
                    {
                      value: "employeeEmail", label: "담당 직원 이메일 주소"
                    }
                  ]}
                />
                <SearchBox
                  width={332}
                  placeholder="수임 업체명, 담당 직원명, 담당 직원 이메일 주소"
                  value={searchData.text}
                  onChange={(e) => setSearchData({ ...searchData, text: e.target.value })}
                  onClick={refetch}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      refetch();
                    }
                  }}
                />
                <SelectBox
                  defaultValue={rows}
                  value={rows}
                  style={{ width: '118px' }}
                  suffixIcon={<i className='svg_icon_16_search_arrow_down'></i>}
                  onChange={(value) => setRows(value)}
                  options={[
                    {
                      value: 10, label: "10줄씩 보기"
                    },
                    {
                      value: 20, label: "20줄씩 보기"
                    },
                    {
                      value: 30, label: "30줄씩 보기"
                    },
                    {
                      value: 50, label: "50줄씩 보기"
                    },
                    {
                      value: 100, label: "100줄씩 보기"
                    }
                  ]}
                />


              </Flex>

              <Popover
                open={openPopover}
                trigger="click"
                onOpenChange={(newOpen) => {
                  setOpenPopover(newOpen);
                }}
                content={<Flex direction="column" rowGap={20} style={{ width: 290, padding: 8 }}>
                  사업장 관리를 위한 휴램은 인사 노무 솔루션으로 사업장의 급여관리/사원관리/연차관리/근태관리를 할 수 있어요. 수임업체는 하나의 휴램 계정에 연결돼요. 담당 직원은 이 휴램을 사용해 사업장을 관리해요.
                  <Button type="line-purple" size="sm" style={{ width: "100%" }} onClick={() => setOpenPopover(false)}>확인</Button>
                </Flex>}
              >
                <NotificationCard
                  size="small"
                  message={<>사업장 관리는 어떻게 하나요?</>}
                  style={{
                    cursor: "pointer"
                  }}
                />
              </Popover>

            </Flex>
          </>
        ) : (
          <Flex style={{ marginTop: 30 }}>
            <NotificationCard
              type="warning"
              style={{
                margin: '24px 0'
              }}
              message={<>수임 업체를 등록하기 위해 4대 보험 연동을 해주세요. 연동이 완료되면 자동으로 수임 업체를 불러옵니다.</>}
            />
          </Flex>
        )
      }

      {author === "manager" ? <Flex justifyContent="flex-end" style={{ position: "relative" }}>
        <Button
          onClick={() => {
            //setIsAddCompany(true);
            if (popup) {
              setPopup(false);
            } else {
              setPopup(true);
            }

          }}
          width={188}
        >
          <Flex justifyContent="center" style={{ width: "100%", height: "100%" }}>
            수임 업체 추가
          </Flex>

          <Flex justifyContent="flex-end" style={{ width: 35, height: "100%" }}>
            <div style={{ height: 35, width: 1, background: "#A8A8A8" }}></div>
            <img src={down} alt="" width={24} />
          </Flex>

        </Button>
        {popup ? <Flex justifyContent="center" alignItems="center" style={{ flexDirection: "column", overflow: "hidden", position: "absolute", top: 60, width: 240, height: 86, background: "white", borderRadius: 10, zIndex: 1, border: "1px solid #E2E5EA" }}>
          <div
            onClick={() => {
              setPopup(false);

              if (certYn === "N" && author === "manager") {
                setInsuranceLinkModalOpen(true);
              } else {
                companyReload();
              }
            }}
            style={{ width: "100%", height: "100%" }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Flex
              justifyContent="flex-start" style={{ height: 40, width: "100%", paddingLeft: 10, cursor: "pointer" }}  >
              4대 보험 연동으로 수임 업체 가져오기
            </Flex>
          </div>
          <div
            style={{ width: "100%", height: "100%" }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => {
              setIsAddCompany(true);
              setPopup(false);
            }} >
            <Flex
              justifyContent="flex-start" style={{ height: 40, width: "100%", paddingLeft: 10, cursor: "pointer" }}>
              수임 업체 직접 등록
            </Flex> 
          </div>
        </Flex> : null}

      </Flex>
        : null}
      <Flex justifyContent="space-between" style={{ marginTop: 20 }}>
        <div style={{ fontSize: "16px", fontWeight: 500, lineHeight: "16px" }}>총 {data?.length || 0}개</div>
        <Flex justifyContent="flex-end" style={{ width: 400 }}>
          {author === "manager" ? <Flex>
            <Toggle htmlFor="toggle5" checked={isHide} onChange={(e) => setIsHide(e.target.checked)} />
            숨긴 사업장 보기

          </Flex> : null}

          <Flex>
            <Toggle htmlFor="toggle4" checked={isDupl} onChange={(e) => setIsDupl(e.target.checked)} />
            수임업체 중복 등록
            <Tooltip
              title={<>
                <img src={tooltipImg} alt="" width={209} style={{ marginBottom: 20, borderRadius: 6 }} />
                동일한 수임업체명인데 서로 다른 사업장 계정이 연결된 곳만 볼 수 있는 기능이에요. 더보기 버튼을 눌러 연결된 휴램 계정 변경하기를 통해  동일한 수임업체로 변경해보세요.
              </>}
              overlayInnerStyle={{ width: 250 }}
            >
              <HelpCircleGrayIcon size={20} />
            </Tooltip>
          </Flex>
        </Flex>

      </Flex>

      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          columns={columns}
          dataSource={data}
          style={{ marginTop: 16 }}
          rows={rows}
          scroll={{
            x: 1500,
          }}
          pagination={{
            pageSize: rows,
            onChange: (_, pageSize) => setRows(pageSize)
          }}
          loading={isLoading}
        />
      </ConfigProvider>

      {
        accountSharingModalOpen && (
          <AccountSharingModal data={selectedData} open={accountSharingModalOpen} handleOk={sharingOk} handleCancel={() => setAccountSharingModalOpen(false)} />
        )
      }

      {
        accountConnectModalOpen && (
          <AccountConnectModal data={selectedData} open={accountConnectModalOpen} handleOk={ConnectOk} handleCancel={() => setAccountConnectModalOpen(false)} />
        )
      }

      {
        insuranceLinkModalOpen && (
          <InsuranceLinkModal open={insuranceLinkModalOpen} handleOk={linkedOk} handleCancel={() => setInsuranceLinkModalOpen(false)} />
        )
      }

      {
        modifyLinkedAccountModal && (
          <ModifyLinkedAccountModal data={selectedData} open={modifyLinkedAccountModal} handleOk={modifyOk} handleCancel={() => setModifyLinkedAccountModal(false)} />
        )
      }

      {
        certImportModal && (
          <PublicCertImportModal open={certImportModal} handleOk={importPublicCert} handleCancel={() => setCertImportModal(false)} />
        )
      }

      <div style={{ height: 130 }}></div>
      {
        empPicker && (
          <EmployeePicker
            data={workObj}
            modalOpen={empPicker}
            modalClose={() => setEmpPicker(false)}
            reload={refetch}
          />
        )
      }
      {isAddCompany&& <CompanyAddModal modalOpen={isAddCompany} handleOk={addCompany } handleCancel={handleCancel} />}
      {isModifyCompany&& <CompanyModifyModal  data={selectedData} modalOpen={isModifyCompany} handleOk={modifyCompany } handleCancel={handleModifyCancel} />}
    </div >
  )
}