import React, { useEffect, useState } from "react";
import { Modal, Input, Form, Col, Row } from 'antd'
import { Button, Flex, NotificationCard, MultiButton } from "components/hulam_platform"

import styles from "./modal.module.css"
import { callApi, extractNumbers, handlerEventLog } from "utils";
import { useAuth } from "AuthProvider";

const businessConfig = {
  rules: [
    {
      required: true,
      message: '사업자 등록 번호를 입력해 주세요.',
    },
  ],
}
const generateRandomUpperCase = () => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const length = 6;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};


export default function CompanyAddModal(props) {
  const { companyName } = useAuth();
  const [resultType, setResultType] = useState("none");
  const [businessCount, setBusinessCount] = useState(0);
  const [form] = Form.useForm();


  const addCompany = async (fieldsValue) => {
    let opId = window.sessionStorage.getItem("userId");
    const postData = {
      opId: opId,
      companyName: fieldsValue['companyName'],
      businessNumber: `${fieldsValue['businessNumber1']}${fieldsValue['businessNumber2']}${fieldsValue['businessNumber3']}`
    };

    const response = await callApi('api/platform/addCompany', postData)

    if (response.result) {
      props.handleOk("success");

      const eventLog = {
        href: window.location.href,
        event_type: 'add_firm_manually',
        userId: opId,
        category: 'add_firm',
        message: "",
        source: "",
        label1: "",
        label2: "",
        slack_message: `[수임업체 직접 등록]\n업무 대행사:${companyName} (${opId})`,
        slack_type: 'onborading'
      }
      await handlerEventLog(eventLog)
    } else {

    }
  }

  const checkCompany = async () => {
    let opId = window.sessionStorage.getItem("userId");

    const fieldsValue = form.getFieldsValue();

    const postData = {
      opId: opId,
      companyName: fieldsValue['companyName'],
      businessNumber: `${fieldsValue['businessNumber1']}${fieldsValue['businessNumber2']}${fieldsValue['businessNumber3']}`
    };

    const response = await callApi('api/platform/checkCompanyBusinessNumber', postData)

    if (response.result) {
      setBusinessCount(`${fieldsValue['businessNumber1']}${fieldsValue['businessNumber2']}${fieldsValue['businessNumber3']}`.length);
      setResultType(response.resultType);
    } else {

    }
  }

  useEffect(() => {
    generateRandomUpperCase();
  }, [])

  return (
    <Modal
      title={"수임업체 추가"}
      open={props.modalOpen}
      closeIcon={false}
      width={800}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      footer={
        <MultiButton>
          <Button onClick={() => form.submit()} width={125}
            isDisabled={(businessCount < 10) ? true : false}>
            등록
          </Button>
          <Button type="secondary" onClick={props.handleCancel} width={125}>
            닫기
          </Button>
        </MultiButton>
      }
    >
      <div>
        <NotificationCard size="small" message={<>4대 보험 연동을 통해 가져온 수임업체가 아닌 경우에만 사용해주세요.</>} />
        <Form
          form={form}
          className={styles.form}
          onFinish={(value) => addCompany(value)}
          requiredMark={false}
          labelCol={{ flex: '120px' }}
        >
          <Row gutter={24} style={{ borderBottom: "1px solid #E2E5EA" }}>
            <Col span={12}>
              <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ width: 120 }}>회사 이름</div>
                <Form.Item
                  name="companyName"
                  noStyle
                  required
                >
                  <Input placeholder="입력" style={{ width: 180 }} />
                </Form.Item>
              </Flex>
            </Col>

          </Row>
          <Row gutter={24} style={{ borderBottom: "1px solid #E2E5EA" }}>
            <Col span={24}>
              <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ width: 120 }}>사업자 등록번호</div>
                <Form.Item
                  noStyle
                  required
                >

                  <Flex columnGap={10}>
                    <Form.Item name="businessNumber1" noStyle {...businessConfig} getValueFromEvent={extractNumbers}>
                      <Input placeholder="입력" onChange={checkCompany} style={{ width: 130 }} maxLength={3} controls={false} />
                    </Form.Item>
                    -
                    <Form.Item name="businessNumber2" noStyle {...businessConfig} getValueFromEvent={extractNumbers}>
                      <Input placeholder="입력" onChange={checkCompany} style={{ width: 130 }} maxLength={2} controls={false} />
                    </Form.Item>
                    -
                    <Form.Item name="businessNumber3" noStyle {...businessConfig} getValueFromEvent={extractNumbers}>
                      <Input placeholder="입력" onChange={checkCompany} style={{ width: 130 }} maxLength={5} controls={false} />
                    </Form.Item>
                  </Flex>
                </Form.Item>

              </Flex>

            </Col>
          </Row>
          <div style={{ marginBottom: 20 }}>{resultType === "matchOne" ? <div>
            <NotificationCard type="warning" size="small" message={<>해당 사업자 등록번호로 매칭되는 휴램 계정이 있어요. <br />
              등록 버튼을 누르면 해당 계정을 사용중인 사용자에게 수임 동의 요청이 자동으로 가요.</>} />
          </div> : null}</div>
          <div style={{ marginBottom: 20 }}>{resultType === "matchTwoOver" ? <div>
            <NotificationCard type="warning" size="small" message={<>해당 사업자 등록번호로 매칭되는 휴램 계정이 한개 이상 있어요. 등록을 누르면 회사가 등록돼요.<br />
              계정 선택을 통해서 회사에 동의 요청을 할 수 있어요.</>} />
          </div> : null}</div>
          <div style={{ marginBottom: 20 }}>{resultType === "normal" ? <div>
            <NotificationCard type="warning" size="small" message={<>해당 사업자 번호로 매칭되는 휴램 계정이 없습니다.<br />해당 사업자 번호는 정상 사업자 번호이며, 등록시에 휴램 계정이 자동으로 생성되어 연결 됩니다.</>} />
          </div> : null}</div>
          <div style={{ marginBottom: 20 }}>{businessCount === 10 && resultType === "none" ? <div>
            <NotificationCard type="warning" size="small" message={<>해당 사업자 등록번호는 국세청에 등록된 정상 사업자 등록번호가 아니에요.<br />
              해당 사업자 등록번호로 수임 업체 등록은 가능해요.</>} />
          </div> : null}</div>

        </Form>
      </div>
    </Modal >
  )
}


