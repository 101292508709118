import React, { useEffect, useState } from "react";
import { Modal, Input, Form, Col, Row } from 'antd'
import { Button, Flex,   NotificationCard, MultiButton } from "components/hulam_platform"

import styles from "./modal.module.css"
import { callApi, extractNumbers } from "utils";

const businessConfig = {
  rules: [
    {
      required: true,
      message: '사업자 등록 번호를 입력해 주세요.',
    },
  ],
}
const generateRandomUpperCase = () => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const length = 6;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};
 

const fields = [
  {
    name: "companyName",
    value: ''
  },
  {
    name: "businessNumber1",
    value: ''
  },
  {
    name: "businessNumber2",
    value: ''
  },
  {
    name: "businessNumber3",
    value: ''
  } 
];
export default function CompanyModifyModal(props) {
  const [data] = useState(props&&props.data);
  
  const [form] = Form.useForm();  
 

  
  const modifyCompany = async (fieldsValue) => {
    let opId = window.sessionStorage.getItem("userId");
    const postData = {
      opId: opId,
      isInsuranceCompany: props&&props.data&&props.data.isInsuranceCompany,
      companyName: fieldsValue['companyName'], 
      businessNumber: `${fieldsValue['businessNumber1']}${fieldsValue['businessNumber2']}${fieldsValue['businessNumber3']}` ,
      originNumber : props&&props.data&&props.data.businessNum, 
      mbId: props&&props.data&&props.data.mbId,
    };
 
    /*
    const postData = {
      opId: opId,
      employeeEmail: data.email + "@" + data.email1,
      password: data.password,
      phone: `${data.phone1}${data.phone2}${data.phone3}`,
      name: data.name,
      role: data.role,
      alarmYn: alarmYn ? "1" : "0"
      // 추가 필드
    };
    */
   console.log(postData);

    const response = await callApi('api/platform/modifyCompany', postData)

    if (response.result) {

      props.handleOk("success");
    } else {

    }
  }
 
  useEffect(() => {
    generateRandomUpperCase();

     
  
  }, [])


  const newData = fields.map(item => {
    // 사업자 등록번호 스플릿
    if (data.businessNum) {
      let businessNumber = data.businessNum.replaceAll("-", "");
      if (item.name === "businessNumber1") {
        return { ...item, value: businessNumber?.substr(0, 3) }
      }

      if (item.name === "businessNumber2") {
        return { ...item, value: businessNumber?.substr(3, 2) }
      }

      if (item.name === "businessNumber3") {
        return { ...item, value: businessNumber?.substr(5, 5) }
      }
    } 

    if (data[item.name]) {
      return { ...item, value: data[item.name] }
    } else {
      return item
    }
  });

  console.log(newData);

  if(data.isInsuranceCompany==="Y"){

  return (
    <Modal
      title={"수임업체 이름 수정"}
      open={props.modalOpen}
      closeIcon={false}
      width={800}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      footer={
        <MultiButton>
          <Button onClick={() => form.submit()} width={125}
           >
            수정
          </Button>
          <Button type="secondary" onClick={props.handleCancel} width={125}>
            닫기
          </Button>
        </MultiButton>
      }
    >
      <div>
         <Form
          form={form}
          className={styles.form} 

          fields={newData}
          onFinish={(value) => modifyCompany(value)}
          requiredMark={false}
          labelCol={{ flex: '120px' }}
        >
          <Row gutter={24} style={{ borderBottom: "1px solid #E2E5EA" }}>
            <Col span={12}>
              <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ width: 120 }}>회사 이름</div>
                <Form.Item
                  name="companyName"
                  noStyle
                  required
                >
                  <Input placeholder="입력" style={{ width: 180 }}  />
                </Form.Item>
              </Flex>
            </Col>
            
          </Row>
           
          
        </Form>
      </div> 
    </Modal >
  )
  }else{


  return (
    <Modal
      title={"수임업체 수정"}
      open={props.modalOpen}
      closeIcon={false}
      width={800}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      footer={
        <MultiButton>
          <Button onClick={() => form.submit()} width={125}
           >
            수정
          </Button>
          <Button type="secondary" onClick={props.handleCancel} width={125}>
            닫기
          </Button>
        </MultiButton>
      }
    >
      <div>
        <NotificationCard size="small" message={<>4대 보험 연동을 통해 가져온 수임업체가 아닌 경우에만 사용해주세요.</>} />
        <Form
          form={form}
          className={styles.form} 

          fields={newData}
          onFinish={(value) => modifyCompany(value)}
          requiredMark={false}
          labelCol={{ flex: '120px' }}
        >
          <Row gutter={24} style={{ borderBottom: "1px solid #E2E5EA" }}>
            <Col span={12}>
              <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ width: 120 }}>회사 이름</div>
                <Form.Item
                  name="companyName"
                  noStyle
                  required
                >
                  <Input placeholder="입력" style={{ width: 180 }}  />
                </Form.Item>
              </Flex>
            </Col>
            
          </Row>
          <Row gutter={24} style={{ borderBottom: "1px solid #E2E5EA" }}>
            <Col span={24}>
              <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ width: 120 }}>사업자 등록번호</div>
                <Form.Item
                noStyle
                required
              >
                
                  <Flex columnGap={10}>
                        <Form.Item name="businessNumber1" noStyle {...businessConfig} getValueFromEvent={extractNumbers}>
                          <Input placeholder="입력"   style={{ width: 130 }} maxLength={3} controls={false}  />
                        </Form.Item>
                        -
                        <Form.Item name="businessNumber2" noStyle {...businessConfig} getValueFromEvent={extractNumbers}>
                          <Input placeholder="입력"   style={{ width: 130 }} maxLength={2} controls={false}  />
                        </Form.Item>
                        -
                        <Form.Item name="businessNumber3" noStyle {...businessConfig} getValueFromEvent={extractNumbers}>
                          <Input placeholder="입력"   style={{ width: 130 }} maxLength={5} controls={false}  />
                        </Form.Item>
                      </Flex>
                </Form.Item>
                
                </Flex>
               
            </Col>
          </Row>
          
        </Form>
      </div> 
    </Modal >
  )
  }

}


