import { message, Modal } from "antd";
import axios from "axios";
import { Button, MultiButton } from "components/button";
import { Flex } from "components/flex";
import { InfoIcon, CheckPrimaryIcon } from "components/icon";

import loadingSpinner from "assets/images/gif_icon/loading-spinner.gif";
const { confirm } = Modal;

/**@function snackBar(copyText)
 * 1. 스낵바 오픈
 * @param { String } copyText
 */
export const snackBar = (text, opt) => {
  const option = {
    type: (opt && opt.type) ? opt.type : "success",
    duration: (opt && opt.duration) ? opt.duration : 3,
  }
  const { type = "success", duration = 3 } = option;
  const getIcon = () => {
    if (type) {
      if (type === "success") {
        return <CheckPrimaryIcon />
      }

      if (type === "loading") {
        return <img src={loadingSpinner} alt="" height={24} />
      }
    } else {
      return <CheckPrimaryIcon />
    }
  }

  message.open({
    type: 'loading',
    icon: getIcon(),
    content: text,
    duration: duration
  });
}

/**@function alert()
 * confirm, alert 모달 오픈
 * @param { Object } options 
 */
export const alert = (options) => {
  const type = options.type ? options.type : "alert";

  const onOk = () => {
    Modal.destroyAll()
    options.onOk && options.onOk()
  }
  const onCancel = () => {
    Modal.destroyAll()
    options.onCancel && options.onCancel()
  }

  confirm({
    centered: true,
    title: <>
      {options.icon ? <InfoIcon /> : ''}
      {options.title ? options.title : ''}
    </>,
    icon: options.icon ? options.icon : <InfoIcon />,
    content: options.content ? options.content : '',
    footer: type === "confirm" ?
      <MultiButton className="ant-modal-confirm-btns" style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
        <Button type="line-gray" size="sm" width={72} onClick={() => onCancel()}>{options.cancelText ? options.cancelText : "취소"}</Button>
        <Button size="sm" width={options.okButtonWidth ? options.okButton : 72} onClick={() => onOk()}>{options.okText ? options.okText : "확인"}</Button>
      </MultiButton> :
      <Flex justifyContent="center">
        <Button size="sm" width={72} style={{ marginTop: 20 }} onClick={() => Modal.destroyAll()}>{options.okText ? options.okText : "확인"}</Button>
      </Flex>
  })
}

/**@function callApi(url, data, method)
 * call api 공통 함수
 * @param { String } url 
 * @param { Object } data
 * @param { String } method
 * @return { Object } response data 
 */
export const callApi = async (url, data = null, method = 'post') => {
  //const {logout} = useAuth();
  //const ROOT_URL = "https://hulam.co.kr";

  //const ROOT_URL = "https://dev2.hulam.co.kr";
  let ROOT_URL = "https://dev2.hulam.co.kr";
  if (window.location.hostname.includes('dev.hulampro.co.kr')) {
    ROOT_URL = "https://dev2.hulam.co.kr";
  } else if (window.location.hostname.includes('hulampro.co.kr')) {
    ROOT_URL = "https://hulam.co.kr";
  } else {
    ROOT_URL = "http://localhost:8000";
  }
  //const ROOT_URL = "http://localhost:8000";
  const ACCESS_TOKEN = window.sessionStorage.getItem("accessToken");
  let error_code = 0;

  try {
    const response = await axios({
      method: method,
      url: `${ROOT_URL}/${url}`,
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`,
        "Content-Type": "application/x-www-form-urlencoded"
      },
      data: JSON.stringify(data)
    })

    return response.data;

  } catch (error) {
    console.log(`[${url}] ${error}`)
    let errorMessage = `${error}`;
    if (errorMessage && typeof errorMessage === 'string' && errorMessage.includes("code 402")) {
      //sessionStorage.clear();
      //window.location.href ="/";
      //logout();
      error_code = 402;
      return error_code;
    }
  }
}


export const callMultiApi = async (url, data = null, method = 'post') => {
  //const ROOT_URL = "https://hulam.co.kr";
  let ROOT_URL = "https://dev2.hulam.co.kr";
  if (window.location.hostname.includes('dev.hulampro.co.kr')) {
    ROOT_URL = "https://dev2.hulam.co.kr";
  } else if (window.location.hostname.includes('hulampro.co.kr')) {
    ROOT_URL = "https://hulam.co.kr";
  } else {
    ROOT_URL = "http://localhost:8000";
  }
  //const ROOT_URL = "http://localhost:8000";
  const ACCESS_TOKEN = window.sessionStorage.getItem("accessToken");

  try {
    const response = await axios({
      method: method,
      url: `${ROOT_URL}/${url}`,
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`,
        "Content-Type": "multipart/form-data"
      },
      data
    })

    return response.data;


  } catch (error) {
    console.log(`[${url}] ${error}`)
  }
}

/**@function numericInput
 * 숫자만 입력 가능
*/
export const extractNumbers = (e) => {
  const { value: inputValue } = e.target;
  return inputValue.replace(/[^0-9]/g, '');
};

/**@function addCommas
 * 컴마 추가
 */
export const addCommas = (number, length = 3) => {
  // 숫자를 문자열로 변환
  const numStr = String(number);

  // 소수점 위치 확인
  const decimalIndex = numStr.indexOf('.');

  // 소수점 이전 부분에 컴마 추가
  let integerPart = decimalIndex > -1 ? numStr.slice(0, decimalIndex) : numStr;
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // 소수점 이후 부분 유지
  const decimalPart = decimalIndex > -1 ? numStr.slice(decimalIndex) : '';

  // 컴마가 추가된 정수 부분과 소수 부분을 합쳐서 반환
  return integerPart + decimalPart;
}


/**
 * @function handlerEventLog
 * @param {String} event_type "click", "pageview" ...
 * @param {String} category "partnership"
 * @param {String} current_path 현재 url
 * @param {String} source "도입 문의하기 버튼", "hulam_home"
 * @param {String} message "로그 남길 때 파악에 도움되는 문구"
 */
export const handlerEventLog = async (eventLog) => {
  const {
    href,
    event_type,
    userId,
    category,
    message,
    source,
    label1,
    label2,
    slack_message,
    slack_type
  } = eventLog;

  let u_id = "";

  const param = {
    href: href,
    userId: userId,
    event_type: event_type,
    category: category,
    message: message,
    source: source,
    label1: label1,
    label2: label2,
    slack_message: slack_message,
    slack_type: slack_type
  }


  if (sessionStorage.getItem("hulam_u_id")) {
    u_id = sessionStorage.getItem("hulam_u_id");
  } else {
    u_id = window.crypto.randomUUID();

    sessionStorage.setItem("hulam_u_id", u_id);
  }

  param.u_id = u_id;

  await callApi("api/setEventLog", param);

}