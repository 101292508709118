
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import './App.scss';

import { Home } from "./pages/Home/Home";
import { HulamHome } from "./pages/Hulam/HulamHome";
import { Login } from "./pages/Home/Login";
import { OperatorLogin } from "./pages/Home/OperatorLogin";
import { EmployeeLogin } from "./pages/Home/EmployeeLogin";
import { SubscriptionFeePage } from "./pages/Home/SubscriptionFeePage";
import { Join } from "./pages/Home/Join";
import { Terms } from "./pages/Home/Terms";
import { HulamCompanyList } from "./pages/Hulam/AssignedClients/HulamCompanyList";

import { OperatorCompanyList } from "./pages/Hulam/Admin/OperatorCompanyList";

import { HulamEmployee } from "./pages/Hulam/EmployeeMansgement/HulamEmployee";
import { HulamSetting } from "./pages/Hulam/Config/HulamSetting";
import { SubscriptionApplication } from "./pages/Hulam/Subscription/SubscriptionApplication";
import { SubscriptionManage } from "./pages/Hulam/Subscription/SubscriptionManage";
import { ConfigProvider, Layout } from "antd";

import locale from "antd/lib/locale/ko_KR";
import Header from "./components/Layouts/Header";
import { AuthLeft } from "components/Layouts/LeftMenu";
import Scrollbars from "react-custom-scrollbars";
import { useAuth } from "AuthProvider";

const CUSTOM_COMPONENT = {
  token: {
    colorPrimary: '#866DEC',
    colorLinkActive: '#866DEC',
    colorLinkHover: '#866DEC',
    fontFamily: "Pretendard, notoSansKR, sans-serif",
  },
  components: {
    Tabs: {
      inkBarColor: "var(--expert-primary-purple-500)",
      itemSelectedColor: "var(--gray-900-heading)",
      itemActiveColor: "var(--gray-900-heading)",
      itemColor: "var(--gray-600)",
      titleFontSize: 16
    },
    Select: {
      borderRadius: 5,
      controlHeight: 38,
      controlPaddingHorizontal: 9,
      colorBorder: "#E2E5EA",
      colorPrimaryHover: "#E2E5EA",
    },
    Alert: {
      colorInfoBg: 'white',
      colorInfoBorder: "var(--gray-200-divider, #E2E5EA)"
    },
    Tooltip: {
      colorBgSpotlight: "var(--gray-700-text)",
    },
    Input: {
      colorBorder: "var(--gray-200-divider)",
      borderRadius: 4,
      controlHeight: 38
    },
    Menu: {
      itemHoverBg: "#ACB1BA",
      itemActiveBg:"#ACB1BA",
      itemSelectedBg: "#ACB1BA",
      itemHeight: 45,
      itemMarginBlock: 0,
      itemMarginInline: 0,
      itemBorderRadius: 0,
      itemHoverColor: "white",
      itemSelectedColor: "white"
    }
  },
}


function App() {
  const { isLoggedIn, author, loadLogin } = useAuth();
 
   
    loadLogin(); 
  return (
    <ConfigProvider
      className="App"
      locale={locale}
      theme={CUSTOM_COMPONENT}
    >
      <Layout style={{ height: "100%" }}>
        <Header isLogin={isLoggedIn} />
        <div style={isLoggedIn ? { height: "100%", background: "#F4F5F8", overflow: 'auto hidden' } : { height: "100%", background: "#fff" }}>
          <div className={isLoggedIn ? "hulam_container" : ""} style={{ height: "100%"}}>
            {isLoggedIn ? <AuthLeft /> : null}
            <Scrollbars className={isLoggedIn ? '' : "hulam_hompage_container"} autoHide style={{ overflow: 'visible' }}>
              <div className="inner" style={{ height: "100%" }}>
                <Routes>
                  <Route
                    path="/"
                    element={isLoggedIn ? <Navigate to="/hulamHome" /> : <Home />}
                  />
                  <Route
                    path="/subscriptionFee"
                    element={isLoggedIn ? <Navigate to="/hulamHome" /> : <SubscriptionFeePage />}
                  />
                  
                  <Route
                    path="/login"
                    element={isLoggedIn ? <Navigate to="/hulamHome" /> : <Login />}
                  />
                  <Route
                    path="/terms/:id"
                    element={isLoggedIn ? <Navigate to="/hulamHome" /> : <Terms  />}
                  />
                  <Route
                    path="/operatorLogin"
                    element={isLoggedIn ? <Navigate to="/hulamHome" /> : <OperatorLogin />}
                  />
                  <Route
                    path="/employeeLogin"
                    element={isLoggedIn ? <Navigate to="/hulamHome" /> : <EmployeeLogin />}

                  />
                  <Route
                    path="/join"
                    element={isLoggedIn ? <Navigate to="/login" /> : <Join />}
                  />

                  <Route
                    path="/hulamHome"
                    element={
                      isLoggedIn ? author==="admin"? <OperatorCompanyList />:
                        author === "manager" ? <HulamHome /> : <Navigate to="/hulamCompanyList" />
                        :
                        <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/hulamCompanyList"
                    element={isLoggedIn ? <HulamCompanyList /> : <Navigate to="/" />}
                  />
                  <Route
                    path="/hulamEmployee"
                    element={
                      isLoggedIn ?
                        author === "manager" ? <HulamEmployee /> : <Navigate to="/hulamCompanyList" />
                        :
                        <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/hulamSetting"
                    element={isLoggedIn ? <HulamSetting /> : <Navigate to="/" />}
                  />
                  <Route
                    path="/hulamSubscription"
                    element={isLoggedIn ? <SubscriptionApplication /> : <Navigate to="/" />}
                  />
                  <Route
                    path="/hulamSubscriptionManage"
                    element={isLoggedIn ? <SubscriptionManage /> : <Navigate to="/" />}
                  />

                  <Route
                    path="/operatorCompanyList"
                    element={isLoggedIn&&author==="admin" ? <OperatorCompanyList /> : <Navigate to="/" />}
                  />
                  {/* 로그인하지 않은 경우 리디렉션 또는 다른 처리 */}
                  {!isLoggedIn && (
                    <Route path="*" element={<Navigate replace to="/" />} />
                  )}
                </Routes>
              </div>
            </Scrollbars>
          </div>
        </div>
      </Layout>
    </ConfigProvider>
  );
}


export default App;
